import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import WalletIcon from "@mui/icons-material/Wallet";
import { fetchCouponData } from "Actions/coupon/action";
import { fetchDashboardData } from "Actions/dashboard/action";
import { storeDileverySlots } from "Actions/location/action";
import { logout } from "Actions/loginotp/action";
import { Slots } from "Actions/myoder/action";
import { fetchPackageData } from "Actions/package/action";
import NotFoundImage from "assets/images/notFound.png";
import { Img, Input, Text } from "components";
import Cart from "components/Cart";
import DrawerBottom from "components/DrawerBottom/DrawerBottom";
import DrawerRight from "components/DrawerRight/DrawerRight";
import { PageLoader } from "components/PageLoader";
import ViewCartButton from "components/ViewCartButton";
import React, { useEffect, useState } from "react";
import { AiFillCaretDown } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../styles/imgs/logo/logo.png";
import iconUser from "../../styles/imgs/theme/icons/icon-user.svg";
import ContentBottomDrawer from "./ContentBottomDrawer/ContentBottomDrawer";
import ContentRightDrawer from "./ContentRightDrawer/ContentRightDrawer";
import "./LandingPageHeader.scss";
import LocationComponent from "./LocationButton";
import { LoginModel } from "./LoginModel";
import MapWithAutocomplete from "./MapAutoComplete";
import MenuListComposition from "./MoreOption";
import "./marquee.css";
import moment from "moment";
import Skeleton from "react-loading-skeleton";

const LandingPageHeader = (props) => {
  const { activepage } = props;
  const [open, setOpen] = React.useState(false);
  const [loginOpen, setloginOpen] = useState(false);
  const [isTokenExpired, setIsTokenExpired] = useState(false);

  const [location1, setLocation] = useState("location loading...");
  const [isNotFound, setNotFound] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [loadingLogout, setLoading] = useState(false);
  const { loading } = useSelector((state) => state.DashboardReducer);
  const { slots, slots_loading } = useSelector((state) => state.Slots);
  const cat = useSelector(
    (state) => state?.DashboardReducer?.dashboardData?.data?.menuList
  );
  const [placeholders, setPlaceholders] = useState([
    {
      name: "milk",
    },
    {
      name: "Baby Care",
    },
    {
      name: "Breakfast Foods",
    },
    {
      name: "Personal Care",
    },
  ]);

  useEffect(() => {
    if (!loading && cat) {
      setPlaceholders(cat);
    }
  }, [loading, cat]);
  useEffect(() => {
    // Rotate placeholders
    const intervalId = setInterval(() => {
      setPlaceholders((prevPlaceholders) => [
        ...prevPlaceholders.slice(1),
        prevPlaceholders[0],
      ]);
    }, 1500); // Change the duration as needed

    return () => clearInterval(intervalId);
  }, []);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.DashboardReducer.dashboardData);

  const location = useSelector((state) => state.LocationReducer.location);
  const navigate = useNavigate();

  const token = useSelector(
    (state) => state.LoginOtpVerifyReducer.verify_result?.data?.access_token
  );
  const token_date = useSelector(
    (state) => state.LoginOtpVerifyReducer.verify_result?.data?.expires_at
  );
  const storeids = useSelector(
    (state) => state.WarehouseReducer?.warehouseData
  );
  console.log("storeids", storeids);
  const [id, setStoreid] = useState("");

  useEffect(() => {
    if (storeids) {
      if (storeids?.length > 0) {
        setOpen(false);
      } else {
        setStoreid(null);
        setOpen(false);
      }
    }
  }, [storeids]);
  const { Dilevery_location } = useSelector((state) => state.DeliveryReducer);
  const locationPath = useLocation();
  useEffect(() => {
    if (storeids && storeids.length>0) {
      setOpen(false);
      setNotFound(false);
    }
  }, [storeids, location1, location]);

  useEffect(() => {
    console.log("storeids[0]",storeids[0],storeids && storeids?.length>0)
    if (storeids && storeids?.length>0) {
      console.log("storeids[0]",storeids[0])
      if (storeids[0]?.idstore_warehouse !== undefined) {
        dispatch(
          fetchPackageData(null, "api/get-packages", storeids[0]?.idstore_warehouse)
        );
        dispatch(
          fetchCouponData("api/get-coupons", null, storeids[0]?.idstore_warehouse)
        );
        dispatch(Slots(null, storeids[0]?.idstore_warehouse));
        if (token === undefined && token_date === undefined) {
          setIsTokenExpired(true);
          dispatch(fetchDashboardData(null, storeids[0]?.idstore_warehouse));
        } else {
          const currentTimestamp = Math.floor(Date.now() / 1000);
          // console.log(token_date < currentTimestamp);
          setIsTokenExpired(token_date < currentTimestamp);
          dispatch(fetchDashboardData(token, storeids[0]?.idstore_warehouse));
        }
      }
    }
  }, [isTokenExpired, token, token_date, storeids, location1, location]);

  useEffect(() => {
    if (location === null) {
      setOpen(true);
    } else {
      if (storeids&&storeids?.length > 0) {
       
        setOpen(false);
      } else {
        setStoreid(null);
        setOpen(false);
      }
    }
  }, [location, storeids, location1]);
  const logoutUser = () => {
    setLoading(true);
    dispatch(logout());
    setIsTokenExpired(true);
    navigate("/", { replace: true });
    setTimeout(() => {
      setLoading(false);
    }, 1800);
  };

  const SearchClick = () => {
    const search = "search=" + searchValue;

    navigate(`/products`, {
      replace: true,
      search,
      state: { type: "search", value: searchValue },
    });
  };
  const onChangeSearch = (e) => {
    setSearchValue(e);
  };
  const handleEnterKeyPressSearch = (event) => {
    if (event.key === "Enter") {
      setTimeout(() => {
        SearchClick();
      }, 500);
    }
  };

  // Bottom Drawer
  const [openBottomDrawer, setOpenBottomDrawer] = React.useState(false);

  const toggleDrawerBottom = (newOpen) => () => {
    setOpenBottomDrawer(newOpen);
  };

  // Right Drawer
  const [openRightDrawer, setOpenRightDrawer] = React.useState({
    right: false,
  });

  const toggleDrawerRight = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenRightDrawer({ ...openRightDrawer, [anchor]: open });
  };
  const onCloseModal = () => setloginOpen(false);

  // console.log("openRightDrawer",openRightDrawer)

  // My Cart
  const [openRightDrawerMyCart, setOpenRightDrawerMyCart] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const goBack = () => {
    navigate(-1);
  };
 
  
  const isWithinTimeRange = () => {
    const currentTime = moment();
    const currentDate = moment().format('YYYY-MM-DD');
    const isCurrentDate=slots?.some((dt)=>dt.date==currentDate)
    const slotsForCurrentDate = slots?.find(item => item.date === currentDate);
    const futureSlots = slotsForCurrentDate?.slots?.filter(slot => moment(slot.start, 'h:mm a').isAfter(currentTime));
    console.log("futureSlots",isCurrentDate)
    if(!isCurrentDate){
      dispatch(storeDileverySlots("No Delivery Slots Available For Today"));
    }
    else{
     
      const startTime = moment(slotsForCurrentDate.slots[0].end, 'h:mm a');
    
    // Calculate the difference
    const diff = moment.duration(startTime.diff(currentTime));
    const hours = Math.floor(diff.asHours());
    if(hours===0){
      dispatch(storeDileverySlots(`Earliest delivery in 30 minutes`));
    }
    else{
      dispatch(storeDileverySlots(`Earliest delivery in ${hours} hour`));
    }
      
    }
    // if (currentTime >= 10 && currentTime <= 19) {
    //   dispatch(storeDileverySlots("Earliest delivery in one hour"));
    //   return "Earliest delivery in one hour";
    // } else {
    //   dispatch(storeDileverySlots("Next-day delivery available"));
    //   return "Next-day delivery available";
    // }
  };
  useEffect(() => {
    if(storeids && storeids.length>0){
      isWithinTimeRange();
    }
    
  }, [storeids,location,location1,slots_loading]);

  // View Cart button
  const { cart_result, cart_id, fetch_cart } = useSelector(
    (state) => state.CartReducer
  );
  const total = cart_result?.length
    ? cart_result.reduce(
        (accumulator, currentValue) => accumulator + currentValue.quantity,
        0
      )
    : 0;

  // ====================
  const path = window.location.search;
  const queryString = path;
  const parts = queryString.split("=");
  const stringAfterEqualsSign = parts.length > 1 ? parts[1] : "";
  const index = queryString.indexOf("=");
  const path1 = index !== -1 ? queryString.substring(index + 1) : "";
  const pathName = path1?.replace(/-/g, " ");
  // console.log("path pathName", pathName?.replace(/-/g, ' '));

  const NaviProfile = () => {
    navigate("/mycart");
  };


  console.log("location",locationPath.pathname )


  return (
    <>
      {loadingLogout ? (
        <PageLoader />
      ) : (
        <>
          <header className={"header-style-1"}>
            <div className="mobile-promotion"></div>

            <div className="sticky-header sticky-bar" style={{ zIndex: 11111 }}>
              <div
                className="header-bottom header-bottom-bg-color header-bottom-bg-color  mb-10 p-1"
                style={{ zIndex: 11111 }}
              >
                <div className="container-fluid">
                  <div className="header-wrap header-space-between position-relative flex-wrap">
                    {/* Deskop View */}
                    <div className="header-nav d-none d-lg-flex">
                      <div className="desktop-view">
                        <div
                          className="logo logo-width-1"
                          style={{ paddingRight: "2rem" }}
                        >
                          <Link to="/">
                            <Img src={logo} alt="logo" />
                          </Link>
                        </div>
                      </div>
                      <div className="header-devider"></div>
                      {storeids &&storeids.length === 0 ? (
                        <div className="desktop-view">
                          <div id="l-sec" onClick={() => setOpen(true)}>
                            <Link
                              to="#"
                              className="location text-decoration-none"
                            >
                              <h6
                                className="text-decoration-none"
                                style={{ color: "black", fontSize: "19px" }}
                              >
                                Delievery Not Available
                              </h6>
                              <p
                                style={{
                                  fontSize: "13px",
                                  // marginTop: "-10px",
                                  color: "black",
                                }}
                                className="flex items-center"
                              >
                                {" "}
                                {location?.length > 20
                                  ? location.slice(0, 20) + "..."
                                  : location}
                                <AiFillCaretDown style />
                              </p>
                            </Link>
                          </div>
                        </div>
                      ) : (
                        <div
                          className="desktop-view"
                          id="l-sec"
                          onClick={() => setOpen(true)}
                        >
                          <Link
                            to="#"
                            className="location text-decoration-none desk"
                          >
                            {
                              slots_loading?<Skeleton/>: <h6 className="text-decoration-none text-base">
                              {Dilevery_location}
                            </h6>
                            }
                            {/* <h6
                              className="text-decoration-none text-base"
                              // style={{ color: "black", fontSize: "19px" }}
                            >
                             

                              {Dilevery_location}
                            </h6> */}
                            <p
                              style={{
                                fontSize: "13px",
                                marginTop: "-2px",
                                color: "black",
                              }}
                              className="flex items-center"
                            >
                              {" "}
                              {location?.length > 23
                                ? location.slice(0, 23) + "..."
                                : location}
                              <AiFillCaretDown />
                            </p>
                          </Link>
                        </div>
                      )}
                    </div>

                    {/* Mobile View  */}
                    <div className="mobile-view">
                      <div
                        className=" flex justify-between items-center w-full gap-[12px]"
                        id="l-sec"
                      >
                        {window.location.pathname !== "/" && (
                          <ArrowBackIcon
                            onClick={goBack}
                            className="close-btn mb-3"
                          />
                        )}

                        <div
                          onClick={toggleDrawerBottom(true)}
                          className="location text-decoration-none w-4/5"
                        >
                          {storeids &&storeids.length === 0 ? (
                            <>
                              <h6 className="title-head ">
                                Delievery Not Available
                              </h6>
                              <div className="flex gap-1 items-center mb-2">
                                <p className="address-text flex items-center mb-0">
                                  {location?.length > 20
                                    ? location.slice(0, 20) + "..."
                                    : location}
                                </p>
                                <AiFillCaretDown />
                              </div>
                            </>
                          ) : (
                            <>
                            {
                              slots_loading?<Skeleton/>: <h6 className="title-head ">
                              {Dilevery_location}
                            </h6>
                            }
                             
                              <div className="flex gap-1 items-center mb-2">
                                <p className="address-text flex items-center mb-0">
                                  {location?.length > 20
                                    ? location.slice(0, 20) + "..."
                                    : location}
                                </p>
                                <AiFillCaretDown />
                              </div>
                            </>
                          )}
                        </div>
                        <div
                          onClick={toggleDrawerRight("right", true)}
                          className="user-icon"
                        >
                          <AccountCircleOutlinedIcon className="w-8 h-8" />
                        </div>
                      </div>
                    </div>

                    {/* Bottom Drawer Open onclick Header Address  */}
                    <DrawerBottom
                      id="drawer-bottom"
                      toggleDrawer={toggleDrawerBottom}
                      openBottomDrawer={openBottomDrawer}
                      content={
                        <ContentBottomDrawer
                          toggleDrawer={toggleDrawerBottom}
                        />
                      }
                    />

                    {/* Right Drawer Open onclick Header User Icon  */}
                    <DrawerRight
                      toggleDrawer={toggleDrawerRight}
                      openRightDrawer={openRightDrawer}
                      content={
                        <ContentRightDrawer
                          toggleDrawer={toggleDrawerRight}
                          isTokenExpired={isTokenExpired}
                          setIsTokenExpired={setIsTokenExpired}
                          openRightDrawer={openRightDrawer}
                          setOpenRightDrawer={setOpenRightDrawer}
                        />
                      }
                    />

                    <div className="header-right w-full" >
                      <div
                        className="search-style-3 w-full"
                        style={{ marginBottom: 0 }}
                      >
                        <div action="#" className="input-style-test w-full">
                          <Link to={"/s/"}>
                            <Input
                              id="marquee"
                              type="text"
                              value={searchValue}
                              className="marquee"
                              wrapClassName="input-container"
                              onChange={onChangeSearch}
                              onKeyDown={handleEnterKeyPressSearch}
                            />
                            <label htmlFor="username" className="label">
                              Search "{placeholders[0]?.name}"
                            </label>
                          </Link>
                        </div>
                      </div>
                      <div className="header-action-right">
                        <div className="header-action-2 gap-4">
                          {isTokenExpired ? (
                            <div className="header-action-icon-2">
                              <Text
                                className="loginmod text-decoration-none"
                                onClick={() => setloginOpen(true)}
                              >
                                <button>Login</button>
                              </Text>
                              <LoginModel
                                open={loginOpen}
                                onCloseModal={onCloseModal}
                              />
                            </div>
                          ) : (
                            <div className="header-action-icon-2">
                              <Img
                                className="svgInject"
                                alt="Nest"
                                src={iconUser}
                              />

                              <span className="lable ml-0"></span>

                              <div className="cart-dropdown-wrap cart-dropdown-hm2 account-dropdown">
                                <ul className="pl-0">
                                  {[
                                    {
                                      title: "My Account",
                                      link: "/profile/account",
                                      icon: <AccountCircleOutlinedIcon />,
                                    },
                                    {
                                      title: "Passbook",
                                      link: "/profile/passbook",
                                      icon: <WalletIcon />,
                                    },
                                    {
                                      title: "Order",
                                      link: "/profile/orders",
                                      icon: <ListAltOutlinedIcon />,
                                    },
                                  ].map((db, key) => (
                                    <li key={key}>
                                      <button
                                        onClick={() => navigate(db.link)}
                                        className="link text-decoration-none"
                                      >
                                        {db.icon}
                                        {db.title}
                                      </button>
                                    </li>
                                  ))}

                                  <li>
                                    <button
                                      className="link text-decoration-none"
                                      onClick={() => logoutUser()}
                                    >
                                      {/* <i className="fi fi-rs-sign-out mr-10"></i> */}
                                      <PowerSettingsNewIcon />
                                      Log out
                                    </button>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          )}
                          {locationPath.pathname === "/checkout" ? (
                            <div style={{ width: 30 }}></div>
                          ) : (
                            <Cart
                              openRightDrawer={openRightDrawerMyCart}
                              setOpenRightDrawer={setOpenRightDrawerMyCart}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {locationPath.pathname === "/" ? null : (
                <>
                  <div className="desktop-view">
                    <div className="cat-hader">
                      <div className="cat-contaner flex gap-2">
                        {data?.data?.menuList?.slice(0, 7).map((mn, key) => {
                          // console.log("pathName == mn?.name", pathName + " == " + mn?.name)
                          // console.log("pathName == mn?.name", pathName == mn?.name)
                          return (
                            <>
                              <Link
                                key={key}
                                to={{
                                  pathname: "/products",
                                  search:
                                    "category=" + mn.name.replace(/\s+/g, "-"),
                                }}
                                state={{
                                  value: mn.idcategory,
                                  type: "category",
                                }}
                                className={`"no-underline" `}
                              >
                                <div
                                  className={`${
                                    pathName == mn?.name
                                      ? "cat-itm active text-green-600"
                                      : "cat-itm"
                                  }`}
                                >
                                  {mn?.name}
                                </div>
                              </Link>
                            </>
                          );
                        })}
                        <MenuListComposition
                          pathName={pathName}
                          data={data?.data?.menuList?.slice(
                            8,
                            data?.data?.menuList?.length
                          )}
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </header>
          {/* <MobileMenu/> */}
          <div className="modal-root-dilog">
            <Modal
              open={open}
              onClose={() => {
                setOpen(false);
                setNotFound(false);
              }}
              classNames={{
                modal: "customModal",
              }}
            >
              <div>
                <h6 className="change-text mb-3">Change Location</h6>
                <div className=" gap-2 justify-start items-center">
                  <LocationComponent
                    setLocation={setLocation}
                    setOpen={setOpen}
                    setNotFound={setNotFound}
                    setMobileLoading={() => {}}
                    // setLoadingLocation={()=>{}}
                  />
                  <div
                    className="flex items-center"
                    style={{ marginLeft: "50%",marginBottom:"10px", marginTop:"10px" }}
                  >
                    <div style={{ color: "#bbb" }}>-</div>
                    <div
                      style={{
                        color: "#7E7E7E",
                        fontSize: 11,
                        fontWeight: 700,
                        borderRadius: "100%",
                        border: "1px solid #bbb",
                        marginLeft: "0px",
                        width: 32,
                        height: 32,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      OR
                    </div>
                    <div>
                      <div style={{ color: "#bbb" }}>-</div>
                    </div>
                  </div>

                  <MapWithAutocomplete
                    setLocation={setLocation}
                    setNotFound={setNotFound}
                    setOpen={setOpen}
                  />
                </div>
                {id == undefined && isNotFound && (
                  <div className="flex flex-col items-center justify-center mt-4">
                    <Img src={NotFoundImage} style={{ width: "27%" }} />
                    <p>Oops!</p>
                    <p
                      className="text-center"
                      style={{
                        paddingLeft: "6rem",
                        paddingRight: "6rem",
                        fontSize: 14,
                      }}
                    >
                      Ghar Ghar Bazaar is not available at <b>{location1}</b> at
                      the moment. Please select a different location.
                    </p>
                  </div>
                )}
              </div>
            </Modal>
            {locationPath.pathname === "/" ? null : (
              <>
                {" "}
                <br />
                <br />
              </>
            )}
          </div>

          {/* View Cart Mobile Button  */}
          <ViewCartButton />
        </>
      )}
    </>
  );
};

LandingPageHeader.defaultProps = {};

export default LandingPageHeader;
