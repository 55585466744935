// actions.js
import axios from "axios";

export const FETCH_DATA_WAREHOUSE_REQUEST = "FETCH_DATA_WAREHOUSE_REQUEST";
export const FETCH_DATA_WAREHOUSE_SUCCESS = "FETCH_DATA_WAREHOUSE_SUCCESS";
export const FETCH_DATA_WAREHOUSE_FAILURE = "FETCH_DATA_WAREHOUSE_FAILURE";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const fetchDataRequest = () => ({
  type: FETCH_DATA_WAREHOUSE_REQUEST,
});

const fetchDataSuccess = (data) => ({
  type: FETCH_DATA_WAREHOUSE_SUCCESS,
  payload: data,
});

const fetchDataFailure = (error) => ({
  type: FETCH_DATA_WAREHOUSE_FAILURE,
  payload: error,
});

export const fetchWarehouse = (token, latt, lang) => {
  return async (dispatch) => {
    dispatch(fetchDataRequest());
    try {
      const config = {
        headers: {},
      };

      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      const response = await axios.get(
        `${BASE_URL}/api/get-stores/${latt}/${lang}`,
        config
      );
      let store_ids = await response.data.data.sort(
        (a, b) => a.distance_in_m - b.distance_in_m
      );
      console.log("response.data",store_ids)
      await dispatch(fetchDataSuccess(store_ids));
    } catch (error) {
      await dispatch(fetchDataFailure(error.message));
    }
  };
};
