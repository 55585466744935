import {
  Autocomplete,
  LoadScript,
  StandaloneSearchBox,
  useJsApiLoader,
} from "@react-google-maps/api";
import { fetchLocationDistasnce, storeLocation } from "Actions/location/action";
import { fetchWarehouse } from "Actions/warehouse/action";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

const MapWithAutocomplete = ({ setLocation, setNotFound, setOpen, isOut }) => {
  const autoCompleteRef = React.useRef(null);
  const [isLocation, setLoc] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);

  const token = useSelector(
    (state) => state.LoginOtpVerifyReducer.verify_result?.data?.access_token
  );
  const storeids = useSelector(
    (state) => state.WarehouseReducer?.warehouseData
  );
  // console.log("storeids", storeids);
 
  const dispatch = useDispatch();
  const onLoad = (autocomplete) => {
    setAutocomplete(autocomplete);
  };
  const onPlaceChanged = async () => {
    setNotFound(false);
    if (autocomplete) {
      if (autocomplete !== null) {
        // const place = await autoCompleteRef?.current?.getPlace();
        const place = await autocomplete.getPlaces()[0]; 
        console.log("Place:", place);
        if( place?.geometry?.location?.lat() &&
        place?.geometry?.location?.lng()){
          await dispatch(
            fetchWarehouse(
              token,
              place?.geometry?.location?.lat(),
              place?.geometry?.location?.lng()
            )
          );
        }
      
      
        await setLoc(true);
        await setLocation(place?.name);
        await dispatch(storeLocation(place?.name));
        if (
          place?.geometry?.location?.lat() &&
          place?.geometry?.location?.lng()
        ) {
        }
      }
    }
  };
  useEffect(() => {
    if ((storeids &&storeids?.length == 0) && isLocation) {
      setNotFound(true);
      setLoc(false);
    } else {
      // const respnsoe=axios.get('https://maps.googleapis.com/maps/api/directions/json?mode=DRIVING&origin=26.8467,80.9462&destination=28.6139,77.2090&sensor=true&units=metric&key=AIzaSyCC3PEezROCu62VElmRZOzDOVGPTMh2rdU')
      // console.log('respnsoe',respnsoe)
    }
  }, [isLocation, storeids]);

  useEffect(() => {
    const bodyElement = document.body;
    bodyElement.classList.add(isOut ? "mobileMap" : "myClass");
    // Remove the class when the component unmounts
    return () => {
      bodyElement.classList.remove(isOut ? "mobileMap" : "myClass");
    };
  }, []);
  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      setNotFound(false);
    }
  };

  return (
    <LoadScript
      googleMapsApiKey="AIzaSyCC3PEezROCu62VElmRZOzDOVGPTMh2rdU"
      libraries={["places"]}
    >
      {/* <Autocomplete
        onLoad={(autocomplete) => (autoCompleteRef.current = autocomplete)}
        onPlaceChanged={onPlaceChanged}
      >
        <input
          type="text"
          placeholder="Enter a location"
          className="form-control"
          style={{ border: "1px solid #f0e9ff", height: 43 }}
          onChange={() => setNotFound(false)}
          onKeyDown={handleKeyPress}
        />
      </Autocomplete> */}
      <StandaloneSearchBox onLoad={onLoad} onPlacesChanged={onPlaceChanged}><input
          type="text"
          placeholder="Enter a location"
          className="form-control"
          style={{ border: "1px solid #f0e9ff", height: 43 }}
          onChange={() => setNotFound(false)}
          onKeyDown={handleKeyPress}
        /></StandaloneSearchBox>
    </LoadScript>
  );
};

export default MapWithAutocomplete;
