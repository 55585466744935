import { Text } from "components";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import onPlaceChanged from './AutoMap';
import place from './AutoMap';


// import { fetchProfileData } from "Actions/profile/action";
import AlertModal from "components/Alert";

// import { LoadScript, GoogleMap, Autocomplete, StandaloneSearchBox } from "@react-google-maps/api";

import { useGeolocated } from "react-geolocated";
import { usePosition } from "use-position";
import AutoMap from "./AutoMap";
import axios from "axios";
import {
  AddressPost,
  fetchAllAddressData,
  updateAddressPost,
} from "Actions/address/action";
import { Loader } from "components/Loader";
import { ClipLoader } from "react-spinners";

export const AddressModel = (props) => {
  const [isReadOnly, setIsReadOnly] = useState(true); //its handle the state of readonly in form input
  const [formVisible, setFormVisible] = useState(false); //maded to make form hidden
 
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    reset,
    formState: { errors },
  } = useForm();
  // console.log("props--", props);
  const [open, setOpen] = useState(false);
  const [isAdd, setAdd] = useState(false);
  const dispatch = useDispatch();
  const [isTokenExpired, setIsTokenExpired] = useState(false);
  const onOpenModal = (data) => {
    reset()
    setOpen(true);
    setAdd(true);
    setEdit(false)
  };
  const [alertAddAddress, setAlertAddAddress] = useState(false);
  const [alertUpdateAddress, setAlertUpdateAddress] = useState(false);

  const onCloseModal = () => {
    setAlertAddAddress(false);
    setOpen(false);
    setAdd(false);
  };
  const token = useSelector(
    (state) => state.LoginOtpVerifyReducer.verify_result?.data?.access_token
  );
  const token_date = useSelector(
    (state) => state.LoginOtpVerifyReducer.verify_result?.data?.expires_at
  );
  useEffect(() => {
    if (token === undefined && token_date === undefined) {
      setIsTokenExpired(true);
    } else {
      const currentTimestamp = Math.floor(Date.now() / 1000);
      setIsTokenExpired(token_date < currentTimestamp);
    }
  }, [isTokenExpired, token, token_date]);

  // const { coords } = useGeolocated({
  //   positionOptions: {
  //     enableHighAccuracy: true,
  //   },
  //   userDecisionTimeout: 5000,
  // });

  const isDesktop = window.innerWidth > 768; // Assuming 768px as the breakpoint for desktop

  const { latitude, longitude, error } = usePosition();
  const [addressMap, setAddressMap] = useState();
  const [edit, setEdit] = useState(true);
  const [isLoader, setLoader] = useState(false);

  const [addressDetails, setAddressDetails] = useState({
    name: props.address ? props.address.name : "",
    address: props.address ? props.address.address : "",
    landmark: props.address ? props.address.landmark : "",
    pincode: props.address ? props.address.pincode : "",
    phone: props.address ? props.address.phone : "",
    tag: props.address ? props.address.tag : "Home",
    is_default: props.address ? props.address.is_default : 0,
    lat: props.address ? props.address.lat : "",
    long: props.address ? props.address.long : "",
  });

  const updateaddress = useSelector(
    (state) => state?.UpdateAddressReducer?.updateaddress
  );

  const addaddress = useSelector(
    (state) => state?.AddAddressReducer?.addaddress
  );
  const handleGeocode = async (address) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=AIzaSyCC3PEezROCu62VElmRZOzDOVGPTMh2rdU`
      );

      const { results } = response.data;

      if (results.length > 0) {
        const { lat, lng } = results[0].geometry.location;
        // console.log("lat,lan", { lat, lng });
        return { lat, lng };
      } else {
        console.error("No results found");
      }
    } catch (error) {
      console.error("Error fetching geocoding data", error);
    }
  };
  const onAddressSubmit = async (data) => {
    setLoader(true)
    setAlertUpdateAddress(false);
    setAlertAddAddress(false);
    // console.log("addressDetails-data", data.address);
    if (addressDetails?.address || data.address) {
      const lat = await handleGeocode(data.address);
      const lang = await handleGeocode(data.address);
      if (lat && lang) {
        data.lat = lat?.lat;
        data.long = lang?.lng;
        data.status = 1;
        data.tag = addressDetails.tag;
        // console.log("addressDetails-data", data);
        if (props.address && props.address.idcustomer_address) {
          // for updating address
          data.id = props ? props.address.idcustomer_address : "";
          await dispatch(
            updateAddressPost(token, "api/customer-address", data)
          );
          await setOpen(false);
          await setEdit(false);
          await dispatch(fetchAllAddressData(token, "customer-address"));
          setLoader(false)

        } else {
          await dispatch(AddressPost(token, "api/customer-address", data));
          await setOpen(false);
          await reset();
          await setAddressMap(null);
          // await setAlertAddAddress(true);
          await dispatch(fetchAllAddressData(token, "customer-address"));
          await setEdit(true);
          setLoader(false)

        }
      }
    }
  };
  const selectTag = (tag) => {
    setAddressDetails({ tag: tag });
  };

  useMemo(() => {
    if (edit === false && addressMap) {
      const pincode =
        addressMap?.address_components[
          addressMap?.address_components?.length - 1
        ]?.long_name;
      console.log(
        addressMap?.geometry?.location?.lat(),
        addressMap?.geometry?.location?.lng()
      );
      setAddressDetails({
        ...addressDetails,
        address: addressMap?.formatted_address,
        pincode: pincode,
      });
      setValue("address", addressMap?.formatted_address);
      setValue("pincode", pincode);
    }
  }, [edit, addressMap]);

  const handleReadOnlyChange = (isReadOnly) => {    //this is a function called in automap.jsx for change readonly
    setIsReadOnly(isReadOnly);
  };
  return (
    <>
      {props && props.address ? (
        <Text
          style={{ cursor: "pointer", color: "rgb(49, 134, 22)" }}
          className="text-decoration-none text-sm hover:underline cursor-pointer"
          onClick={() => {
            onOpenModal();
            setEdit(true);
            handleReadOnlyChange(false);
            setFormVisible(true);
          }}
        >
          Edit
        </Text>
      ) : (
        <>
          {token && (
            <Text
              style={{ cursor: "pointer", color: "rgb(49, 134, 22)" }}
              className="text-decoration-none text-xs text-center"
              onClick={onOpenModal}
            >
              + Add Address{" "}
            </Text>
          )}
        </>
      )}

      <Modal
        open={open}
        classNames={{
          overlay: "addressOverlay",
          modal: "addressModal",
        }}
        onClose={onCloseModal}
        center
      >
        <div
          className="modal-dialog "
          style={{ maxWidth: "750px", margin: "0" }}
        >
          <div className="modal-content">
            <div className="modal-body" style={{ padding: "0" }}>
              {alertAddAddress ? (
                <>
                  <AlertModal
                    is_show={alertAddAddress}
                    data={addaddress}
                    outtime="3000"
                    Msg="Address added successfully"
                  />
                </>
              ) : null}
              {alertUpdateAddress ? (
                <>
                  <AlertModal
                    is_show={alertUpdateAddress}
                    data={updateaddress}
                    outtime="3000"
                    Msg="Address updated successfully"
                  />
                </>
              ) : null}

              <div className="container-fluid px-0 overflow-x-hidden">
                <div className="grid grid-cols-12 gap-3">
                  <div className="mobile-view sm:col-span-12 col-span-6 sm:pt-4 sm:px-2">
                    <h4
                      style={{
                        color: "rgb(0, 0, 0)",
                        fontSize: 20,
                        fontWeight: 900,
                      }}
                    >
                      Enter complete address
                    </h4>
                    <p style={{ fontSize: 14, color: "rgb(130, 130, 130)" }}>
                      This allow us to find you easily and give you timely
                      delivery experience
                    </p>
                  </div>
                  <div className="sm:col-span-12 col-span-6 sm:h-96 relative"  style={{ width: isDesktop ? (formVisible ? "100%" : "208%") : "", height:"600px" }}>
                    {/* google map screen */}
                    <AutoMap setData={setAddressMap}
                     handleReadOnlyChange={handleReadOnlyChange} 
                     setFormVisible={setFormVisible} />

                  </div>
                  {formVisible && (                                     //wrapped the form in this to hide
                   <div className="sm:col-span-12 col-span-6 sm:px-2">
                    <form className="right-form"
                      onSubmit={handleSubmit(onAddressSubmit)}
                      style={{ margin: "30px 0 30px"}}
                      >
                      <h4
                        style={{
                          color: "rgb(0, 0, 0)",
                          fontSize: 20,
                          fontWeight: 900,
                        }}
                      >
                        Enter complete address
                      </h4>
                      <p style={{ fontSize: 14, color: "rgb(130, 130, 130)" }}>
                        This allow us to find you easily and give you timely
                        delivery experience
                      </p>
                      <div className="row mb-1">
                        {/* <div className="col-lg-4">
                                    <select className="form-control" style={{ border: "1px solid grey", height: "40px" }}>
                                        <option>Mr.</option>
                                        <option>Mrs.</option>
                                    </select>
                                </div> */}
                        <div className="error-frm col-lg-12">
                          <input
                            className="input-style-address"
                            name=""
                            readOnly={isReadOnly}
                            style={{ height: "40px" }}
                            {...register("name", {
                              required: "First Name is required",
                            })}
                            value={addressDetails.name}
                            onChange={(e) => setAddressDetails(e.target.value)}
                            placeholder="Name"
                          />
                          {errors.name && <p>{errors.name.message}</p>}
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="error-frm col-lg-12">
                          {addressMap ? (
                            <input
                              className="input-style-address"
                              name=""
                              readOnly={isReadOnly} 
                              style={{ height: "40px" }}
                              {...register("address")}
                              value={addressDetails.address}
                              onChange={(e) =>
                                setAddressDetails(e.target.value)
                              }
                              placeholder="Address"
                            />
                          ) : (
                            <input
                              className="input-style-address"
                              name=""
                              readOnly={isReadOnly} 
                              style={{ height: "40px" }}
                              {...register("address", {
                                required: "Address is required",
                              })}
                              value={addressDetails.address}
                              onChange={(e) =>
                                setAddressDetails(e.target.value)
                              }
                              placeholder="Address"
                            />
                          )}

                          {errors.address && <p>{errors.address.message}</p>}
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="error-frm col-lg-12">
                          <input
                            className="input-style-address"
                            name=""
                            readOnly={isReadOnly} 
                            style={{ height: "40px" }}
                            {...register("landmark")}
                            value={addressDetails.landmark}
                            onChange={(e) => setAddressDetails(e.target.value)}
                            placeholder="Landmark"
                          />
                          {errors.landmark && <p>{errors.landmark.message}</p>}
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div
                          className="error-frm  col-lg-12 mb-1"
                          style={{ marginBottom: 5 }}
                        >
                          {addressMap ? (
                            <input
                              className="input-style-address"
                              name=""
                              readOnly={isReadOnly} 
                              style={{ height: "40px" }}
                              {...register("pincode")}
                              value={addressDetails.pincode}
                              onChange={(e) =>
                                setAddressDetails(e.target.value)
                              }
                              placeholder="Pincode"
                            />
                          ) : (
                            <input
                              className="input-style-address"
                              name=""
                              readOnly={isReadOnly} 
                              style={{ height: "40px" }}
                              {...register("pincode", {
                                required: "Pincode is required",
                              })}
                              value={addressDetails.pincode}
                              onChange={(e) =>
                                setAddressDetails(e.target.value)
                              }
                              placeholder="Pincode"
                            />
                          )}

                          {errors.pincode && <p>{errors.pincode.message}</p>}
                        </div>
                        <div className="error-frm col-lg-12">
                          <input
                            className="input-style-address"
                            name=""
                            readOnly={isReadOnly} 
                            style={{ height: "40px" }}
                            {...register("phone", {
                              required: "Mobile No is required",
                              maxLength: {
                                value: 10,
                                message: "Mobile No should have 10 digit long",
                              },
                              minLength: {
                                value: 10,
                                message: "Mobile No should have 10 digit long",
                              },
                            })}
                            value={addressDetails.phone}
                            onChange={(e) => setAddressDetails(e.target.value)}
                            placeholder="Phone"
                          />
                          {errors.phone && <p>{errors.phone.message}</p>}
                        </div>
                      </div>
                      <div className="row mb-1">
                        <div className="col-lg-12 flex items-center">
                          <input
                            className="form-control"
                            defaultChecked={addressDetails.is_default}
                            type="checkbox"
                            style={{
                              border: "1px solid grey",
                              height: "17px",
                              width: "17px",
                              float: "left",
                              marginRight: "7px",
                              padding: 0,
                              borderRadius: 4,
                            }}
                            {...register("is_default")}
                            value="1"
                            onChange={(e) => setAddressDetails(e.target.value)}
                          />
                          <label style={{ fontSize: "12px", marginTop: 4 }}>
                            Is Default Address?
                          </label>
                        </div>
                      </div>
                      <span
                        style={{ fontSize: 14, color: "rgb(130, 130, 130)" }}
                      >
                        Save address as
                      </span>
                      <ul
                        style={{ display: "flex", paddingLeft: "0" }}
                        className="addchoosehtype"
                      >
                        <li>
                          <a
                            className={
                              addressDetails.tag == "Home" ? "active" : ""
                            }
                            onClick={() => selectTag("Home")}
                            href="#"
                          >
                            Home
                          </a>
                        </li>
                        <li>
                          <a
                            className={
                              addressDetails.tag == "Work" ? "active" : ""
                            }
                            onClick={() => selectTag("Work")}
                            href="#"
                          >
                            Work
                          </a>
                        </li>
                        <li>
                          <a
                            className={
                              addressDetails.tag == "Other" ? "active" : ""
                            }
                            onClick={() => selectTag("Other")}
                            href="#"
                          >
                            Other
                          </a>
                        </li>
                      </ul>
                      <input
                        className="form-control"
                        type="hidden"
                        {...register("tag", { required: "Tag is required" })}
                        value={addressDetails.tag}
                        onChange={(e) => setAddressDetails(e.target.value)}
                      />
                      {errors.tag && <p>{errors.tag.message}</p>}

                      <div
                        className="row mb-1 other_se"
                        style={{ display: "none" }}
                      >
                        <div className="col-lg-12">
                          <input
                            className="form-control"
                            name=""
                            style={{ border: "1px solid grey", height: "40px" }}
                          />
                        </div>
                      </div>
                      <div className="row mb-1 other_se">
                        <div
                          className="col-lg-12"
                          style={{ textAlign: "center", marginTop: "20px" }}
                        >
                          {isLoader ?
                            <button
                              className="btn btn-lg btn-block btn-success w-full"
                              style={{
                                fontSize: 0,
                                background: "grey",
                                cursor: 'not-allowed'
                              }
                              }
                            >
                              <ClipLoader color="white" size={20} />
                            </button>
                            :
                            <button
                              className="btn btn-lg btn-block btn-success w-full"
                              style={{
                                fontSize: 14,
                                background: "rgb(12, 131, 31)",
                              }
                              }
                            >
                              Save Address
                            </button>
                          }
                        </div>
                      </div>
                    </form>
                  </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
